<script setup lang="ts">
interface KSectionProps {
  ctaHome?: string
  icon?: string
  headline?: string
  title: string
  description?: string
  hasCard?: boolean
  isFirstSection?: boolean
}

const props = withDefaults(defineProps<KSectionProps>(), {
  ctaHome: undefined,
  hasCard: false,
  icon: undefined,
  headline: '',
  description: '',
})

const localePath = useLocalePath()
const slots = useSlots()

const hasCtaHome = computed(() => !!slots.ctaHome || !!props.ctaHome)
const hasIcon = computed(() => !!props.icon)
const hasHeadline = computed(() => !!slots.headline || !!props.headline)
const hasTitle = computed(() => !!slots.title || !!props.title)
const hasDescription = computed(
  () => !!slots.description || !!props.description
)
const bodyClasses = computed(() =>
  props.hasCard
    ? 'p-6 sm:p-16 lg:p-24 ke-shadow rounded-2xl bg-white dark:bg-neutral-900'
    : ''
)
const sectionClasses = computed(() => [
  // Default classes
  'py-16 sm:py-24 lg:py-32',
  // Conditional classes
  props.isFirstSection ? 'pt-8 sm:pt-16 lg:pt-24' : '',
])

const hasFooter = computed(() => !!slots.footer)
</script>

<template>
  <UContainer :class="sectionClasses">
    <!-- <div class="grid place-items-center w-full">
      <div
        class="relative flex size-full items-center justify-center overflow-hidden h-[350px] w-full"
      >
        <div class="z-10 text-center">
          <div class="mb-2 text-base/7 font-semibold text-primary">
            {{ t('joinUs.headline') }}
          </div>

          <h2 class="text-3xl lg:text-5xl font-bold mb-4">
            {{ t('joinUs.title') }}
          </h2>
          <p
            class="text-lg text-neutral-600 dark:text-neutral-300 max-w-3xl mx-auto"
          >
            {{ t('joinUs.description') }}
          </p>
        </div>
        <DotPattern
          :width="20"
          :height="20"
          :cx="1.5"
          :cy="1.5"
          :cr="1.5"
          :class="
            cn(
              '[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]'
            )
          "
        />
      </div>
    </div> -->

    <!-- Section with title and description -->
    <UButton
      v-if="hasCtaHome"
      icon="i-tabler-arrow-left"
      color="gray"
      variant="ghost"
      :to="localePath('/')"
      class="mb-6"
    >
      {{ props.ctaHome }}
    </UButton>

    <div
      v-if="hasHeadline || hasTitle || hasDescription || hasIcon"
      class="mb-8 lg:mb-16 text-center flex flex-col items-center"
    >
      <div v-if="hasIcon" class="flex mb-6">
        <UIcon
          :name="props.icon ?? ''"
          class="w-10 h-10 flex-shrink-0 text-primary"
          aria-hidden="true"
        />
      </div>
      <div
        v-if="hasHeadline"
        class="mb-2 text-base/7 font-extrabold text-primary-500 capitalize"
      >
        <slot name="headline">{{ props.headline }}</slot>
      </div>

      <h2 v-if="hasTitle" class="text-3xl lg:text-5xl font-bold mb-4">
        <slot name="title">{{ props.title }}</slot>
      </h2>
      <p
        v-if="hasDescription"
        class="text-md sm:text-xl text-neutral-500 dark:text-neutral-400 max-w-3xl mx-auto"
      >
        <slot name="description">{{ props.description }}</slot>
      </p>
    </div>

    <div :class="bodyClasses">
      <!-- Grid container for desktop layout -->
      <slot name="body" ></slot>
    </div>

    <div v-if="hasFooter" class="pt-8 sm:pt-16 lg:pt-24">
      <slot name="footer" ></slot>
    </div>
  </UContainer>
</template>
